<template>
  <div class="shop-order">
    <el-card class="top-card">
      <el-button
        :type="tabIndex == i ? 'primary' : 'default'"
        v-for="(item, i) in tabList"
        :key="i"
        @click="changeTab(i)"
        >{{ item }}</el-button
      >
    </el-card>
    <el-card class="center-card">
      <div class="statistics-list">
        <div class="box">
          <div class="count">$0.00</div>
          <div class="label">{{ $t('dai-dao-zhang-jin-e') }}</div>
        </div>
        <div class="box">
          <div class="count">$0.00</div>
          <div class="label">{{ $t('zong-xiao-shou-e') }}</div>
        </div>
        <div class="box">
          <div class="count">${{TotalProfitSum.toFixed(2)}}</div> 
          <div class="label">{{ $t('zong-li-run') }}</div>
        </div>
        <div class="box">
          <div class="count">{{TotalOrdersSum}}</div>
          <div class="label">{{ $t('zong-ding-dan-0') }}</div>
        </div>
        <div class="box">
          <div class="count">{{CancelledSum}}</div>
          <div class="label">{{ $t('qu-xiao-ding-dan') }}</div>
        </div>
        <div class="box">
          <div class="count">{{RefundedSum}}</div>
          <div class="label">{{ $t('tui-kuan-ding-dan') }}</div>
        </div>
      </div>
    </el-card>
    <el-card class="main-card table-page">
      <div class="main-table">
        <div class="table-container">
          <el-table
            :data="tableData"
            border
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              align="center"
              :label="$t('ri-qi')"
              prop="Date"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('zong-ding-dan-0')"
              prop="TotalOrders"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('li-run-0')"
              prop="TotalProfit"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('qu-xiao-ding-dan')"
              prop="Cancelled"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('tui-kuan-ding-dan-0')"
              prop="Refunded"
            ></el-table-column>
          </el-table>
        </div>
      </div>
      <div class="bottom-page flex-center">
        <el-pagination
          background
          @size-change="sizeChange"
          @current-change="currentChange"
          :current-page="page.current"
          :page-size="page.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import { shopReport } from '@/api/shop'
import moment from 'moment'
export default {
  data() {
    return {
      tabList: [
        this.$t('zuo-ri'),
        this.$t('jin-ri'),
        this.$t('ben-zhou'),
        this.$t('ben-yue'),
        this.$t('ben-nian'),
        this.$t('quan-bu')
      ],
      tabIndex: 5,
      form: {},
      tableData: [],
      selectRows: [],
      page: {
        size: 10,
        current: 1,
        total: 0
      },
      payStatusList: [
        {
          value: 1,
          label: this.$t('yi-zhi-fu')
        },
        {
          value: 2,
          label: this.$t('wei-zhi-fu')
        }
      ],
      logisticsStatusList: [
        {
          value: 1,
          label: this.$t('yi-fa-huo')
        },
        {
          value: 2,
          label: this.$t('dai-shou-huo')
        },
        {
          value: 3,
          label: this.$t('yi-qian-shou')
        }
      ],
      detailData: {},
      showOrderDialog: false,
      showLogisticsDialog: false,
      TotalOrdersSum: 0,
      TotalProfitSum: 0,
      CancelledSum: 0,
      RefundedSum: 0,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let start = ''
      let end = ''
      switch(this.tabIndex) {
        case 0 : end = moment().subtract(1, 'days').format('YYYY-MM-DD'); start = moment().subtract(1, 'days').format('YYYY-MM-DD'); break;
        case 1 : end = moment().format('YYYY-MM-DD'); start = moment().format('YYYY-MM-DD'); break;
        case 2 : end = moment().endOf('weeks').format('YYYY-MM-DD'); start = moment().startOf('weeks').format('YYYY-MM-DD'); break;
        case 3 : end = moment().endOf('month').format('YYYY-MM-DD'); start = moment().startOf('month').format('YYYY-MM-DD'); break;
        case 4 : end = moment().endOf('year').format('YYYY-MM-DD'); start = moment().startOf('year').format('YYYY-MM-DD'); break;
      }
      shopReport({
        current: this.page.current,
        pageSize: this.page.size,
        StartDate: start ? `${start} 00:00:00` : '',
        EndDate: end ? `${end} 23:59:59` : '',
      }).then(res => {
        this.tableData = res.data || []
        this.page.total = this.tableData.length
        this.TotalOrdersSum = this.tableData.reduce((prev, cur) => prev + cur.TotalOrders, 0)
        this.TotalProfitSum = this.tableData.reduce((prev, cur) => prev + cur.TotalProfit, 0)
        this.CancelledSum = this.tableData.reduce((prev, cur) => prev + cur.Cancelled, 0)
        this.RefundedSum = this.tableData.reduce((prev, cur) => prev + cur.Refunded, 0)
      })
    },
    changeTab(i) {
      this.tabIndex = i
      this.init()
    },
    handleSelectionChange(val) {
      this.selectRows = val
    },
    sizeChange(size) {},
    currentChange(page) {},
    orderDetail(data) {
      this.detailData = data
      this.showOrderDialog = true
    },
    orderLogistics(data) {
      this.showLogisticsDialog = true
    }
  }
}
</script>